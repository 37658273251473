import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import ogImage from '../images/og-relution.jpg'

const SEO = ({ title, seo, site, pageContext }) => {
  // strapiGlobal {
  //   metaTitleTemplate
  //   seo {
  //     metaTitle
  //     metaDescription
  //     metaImage {
  //       data {
  //         attributes {
  //           name
  //         }
  //       }
  //     }
  //     meta {
  //       name
  //       content
  //     }
  //     canonicalUrl
  //   }
  // }

  const { config } = useStaticQuery(graphql`
    {
      config: site {
        siteMetadata {
          title
          description
          siteUrl
          meta {
            author
            copyright
          }
        }
      }
    }
  `)

  const siteMetadata = _.get(config, 'siteMetadata', {})
  const strapiGlobal = _.get(site, 'nodes[0]', {})
  const globalSeo = _.get(strapiGlobal, 'seo', {})
  const { metaTitle: seoMetaTitle } = seo || {}
  const { localizations = [] } = pageContext || {}

  // console.log('Page Context', pageContext)
  // console.log('Site Meta', siteMetadata)
  // console.log('Global SEO', globalSeo)
  // console.log('Page Title', title)
  // console.log('Page SEO', seo)

  const metaTitleTemplate = strapiGlobal.metaTitleSuffix
    ? `%s - ${strapiGlobal.metaTitleSuffix}`
    : '%s'
  const metaTitle =
    seoMetaTitle || title || strapiGlobal.metaTitle || siteMetadata.title

  // Merge default and page-specific SEO values
  const fullSeo = {
    ...globalSeo,
    ...seo,
    metaTitleTemplate,
    metaTitle,
    // favicon: global.favicon,
  }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle,
        },
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        },
      )
    }

    if (fullSeo.shareImage) {
      const imageUrl = process.env.GATSBY_STRAPI_URL
        ? fullSeo.shareImage.publicURL
        : `http://localhost:8000${fullSeo.shareImage.publicURL}`

      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        },
      )
    } else {
      const imageUrl = ogImage
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        },
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      })
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' })

    return tags
  }

  const metaTags = getMetaTags()

  const languageAlternates = localizations.map((localization) => ({
    rel: 'alternate',
    hreflang: localization.locale,
    //href:  withPrefix(localization.route) || '/',
    href:
      localization.locale == 'de'
        ? withPrefix('')
        : withPrefix(localization.locale),
  }))

  return (
    <Helmet
      // NOTE: To ensure that language-specific characters (like turkish) are
      // displayed correctly, the language must already be defined on the server side
      // at build time.
      // @see also gatsby-ssr.js -> onRenderBody and LanguageSelect
      htmlAttributes={{
        lang: pageContext.locale || 'de',
      }}
      title={fullSeo.metaTitle}
      titleTemplate={fullSeo.metaTitleTemplate}
      meta={metaTags}
      link={[
        {
          rel: 'icon',
          href: _.get(fullSeo, 'favicon.localFile.publicURL'),
        },
        ...languageAlternates,
      ]}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  seo: PropTypes.object,
}

export default SEO
